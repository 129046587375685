<template>
  <v-card>
    <v-card-text>
      <div>
        <v-btn @click="registerAllGlobalActions()">Register</v-btn>
        <v-btn @click="unregisterAllGlobalActions()">Unregister</v-btn>
      </div>
      <div>
        {{availableGlobalActions}}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import globalActions from '@/mixins/global-actions'

export default {
  mixins: [globalActions],
  data () {
    return {
      context: 'from the sample component',
      actions: []
    }
  },
  created () {
    this.addGlobalActions(
      { typeName: 'Log into console', icon: this.$icon('i.Any'), callback: this.callback.bind(this) },
      { typeName: 'Log into console', icon: this.$icon('i.Any'), callback: this.callback.bind(this, 'additionnal argument') }
    )
  },
  methods: {
    callback (...args) {
      console.log('I got called ' + this.context, args)
    }
  }
}
</script>
